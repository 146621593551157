import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  AuthTokenService,
  getRedirectUrl,
  Go,
  RemoveTranslations,
  SessionStorageService,
  USER_LOGIN,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_REDIRECT,
  UserLogin,
  UserLoginFail,
  UserLoginSuccess,
  UserLogout,
  UserLogoutFail,
  UserLogoutSuccess,
  UserRedirect,
  UserUpdateLastLogin,
  WINDOW_REF
} from '@ui/legacy-lib';

import {
  logoutMutation,
  LogoutResult,
  ssoQuery,
  SSOQueryResult
} from '../../queries';

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private authTokenService = inject(AuthTokenService);
  private apollo = inject(Apollo);
  private sessionStorage = inject(SessionStorageService);
  private windowRef = inject(WINDOW_REF);

  userLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(USER_LOGIN),
        switchMap((action: UserLogin) => {
          const { email, redirectUri, loginMethod } = action.userCredentials;

          return this.apollo
            .query({
              query: ssoQuery,
              variables: { email, redirectUri, loginMethod }
            })
            .pipe(
              map((result: ApolloQueryResult<SSOQueryResult>) => {
                const { errors } = result;
                if (errors && errors.length)
                  return new UserLoginFail(errors[0].message);

                if (!result.data || !result.data.sso)
                  return new UserLoginFail('UNRECOGNIZED_DATA_RECEIVED');
                const { nonce, state, url } = result.data.sso;

                this.sessionStorage.setItem('nonce', nonce);
                this.sessionStorage.setItem('state', state);
                window.location.href = url;
              }),
              catchError(err => of(new UserLoginFail(err.message)))
            );
        })
      ),
    { dispatch: false }
  );

  userLoginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_LOGIN_FAIL),
      map(() => new UserRedirect())
    )
  );

  userLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_LOGIN_SUCCESS),
      mergeMap((action: UserLoginSuccess) => {
        this.authTokenService.setToken(action.token);
        const data = action?.stateAfterAuth?.pathAfterAuth?.split('?');
        const queryParams = {};
        data?.[1]?.split('&')?.forEach(item => {
          const split = item.split('=');
          queryParams[split[0]] = split[1];
        });
        return [
          new UserUpdateLastLogin(),
          new Go({
            path: [`/${data[0]}`],
            query: queryParams
          })
        ];
      })
    )
  );

  userLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserLogout>(USER_LOGOUT),
      switchMap(action => {
        const redirectUri = action.redirectUrl
          ? action.redirectUrl
          : getRedirectUrl(window.location.toString(), '/login', null);

        return this.apollo
          .mutate({ mutation: logoutMutation, variables: { redirectUri } })
          .pipe(
            mergeMap((result: ApolloQueryResult<LogoutResult>) => {
              const { errors } = result;
              if (errors && errors.length)
                return [new UserLogoutFail(errors[0].message)];

              if (!result.data || !result.data.userLogout) {
                return [new UserLogoutFail('UNRECOGNIZED_DATA_RECEIVED')];
              }

              const { url } = result.data.userLogout;

              this.sessionStorage.removeItem('nonce');
              this.sessionStorage.removeItem('state');
              this.authTokenService.removeToken();
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return [
                new RemoveTranslations() as any,
                new UserLogoutSuccess(url)
              ];
            }),
            catchError(err => [new UserLogoutFail(err)])
          );
      })
    )
  );

  userLogoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(USER_LOGOUT_SUCCESS),
        map(
          (action: UserLogoutSuccess) =>
            (window.location.href = action.redirectUrl)
        )
      ),
    { dispatch: false }
  );

  userRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserRedirect>(USER_REDIRECT),
      map(
        ({ pathAfterAuth }) =>
          new Go({
            path: ['/auth/login'],
            extras: { queryParams: { pathAfterAuth } }
          })
      )
    )
  );
}
